<template>
  <div
    v-if="pagination"
    class="px-table-pagination"
  >
    <ul class="px-pagination">
      <span class="px-pagination-total">{{ $t('grid_monitor.total') }} {{ filteredData.length }} </span>
      <li
        :class="`px-pagination-li px-pagination-prev ${pageIndex === 1 ? 'px-pagination-disabled' : ''}`"
        @click="onUpdatePagination(pageIndex - 1, pageIndex !== 1)"
      >
        <a><i class="px-icon iconfont-vet icon-vet-left-arrow" /></a>
      </li>
      <span class="px-pagination-pager">
        <li
          v-for="page in pages"
          :key="page"
          :class="`px-pagination-li ${pageIndex === (page) ? 'px-pagination-li-active' : ''}`"
          @click="onUpdatePagination(page)"
        >
          <a>{{ page }}</a>
        </li>
      </span>
      <li
        :class="`px-pagination-li px-pagination-next ${isFinalPage ? 'px-pagination-disabled' : ''}`"
        @click="onUpdatePagination(pageIndex + 1, !isFinalPage)"
      >
        <a><i class="px-icon iconfont-vet icon-vet-right-arrow" /></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CMTablePagination',

  props: {
    pagination: {
      type: Boolean,
      default: true
    },

    filteredData: {
      type: Array,
      default: () => ([])
    },

    pageSize: {
      type: Number,
      default: 1
    },

    pageIndex: {
      type: Number,
      default: 1
    }
  },

  computed: {
    pages () {
      const totalItems = this.filteredData.length
      const hasFewItems = totalItems < this.pageSize
      const hasManyItems = (totalItems / this.pageSize) > this.pageSize
      const baseArr = new Array(totalItems)

      if (hasManyItems) {
        return this.pageIndex > 10
          ? [...this.createArrRange(baseArr.slice(0, 10)), this.pageIndex]
          : this.createArrRange(baseArr.slice(0, 10))
      }

      return hasFewItems
        ? [1]
        : this.createArrRange(baseArr.slice(0, Math.ceil(totalItems / this.pageSize)))
          .map((_, index) => index + 1)
    },

    isFinalPage () {
      return this.pageIndex >= Math.ceil(this.filteredData.length / this.pageSize)
    }
  },

  methods: {
    onUpdatePagination (pageIndex, condition = true) {
      if (condition) {
        this.$emit('onUpdateKey', {
          key: 'pageIndex',
          value: pageIndex || 1
        })
      }
    },

    createArrRange (arr) {
      return [...arr.keys()].map((_, index) => index + 1)
    }
  }
}
</script>

<style scoped>
.px-table {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.px-table.px-table-border-around {
 border: 1px solid #000;
}

.px-table-content-wrapper {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
}

.px-table-enable-cell-selection {
  user-select: none;
}

.px-table-container {
  position: relative;
  overflow-y: auto;
  width: 100%;
}

.px-table * {
  box-sizing: border-box;
}

.px-table-content {
  min-width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
}

.px-table-body-tr:hover td {
  background-color: #121212 !important;
}

.px-table-body-tr {
  height: 40px;
}

.px-table-body-td {
  white-space: pre-wrap;
  background-color: #141414;
  color: #ffffffd9;
  height: inherit;
  font-size: 14px;
  overflow: hidden;
  text-align: center;
  border-bottom: 1px solid #000;
}

.px-checkbox {
  cursor: pointer;
  font-size: 12px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.px-table-checkbox-wrapper {
  width: 25px;
  display: inline-block;
}

.px-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.px-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #abbacc;
  border-radius: 2px;
  background-color: #fff;
  transition: all 0.3s;
}

.px-checkbox-filter {
  display: inline-block;
}

.px-checkbox .px-checkbox-checked .px-checkbox-inner {
  background-color: #f4c020;
  border-color: #f4c020;
}

.px-checkbox-inner:after {
  transform: rotate(45deg) scale(0);
  position: absolute;
  left: 4px;
  top: 2px;
  display: table;
  width: 6px;
  height: 9px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: " ";
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6)
}

.px-checkbox .px-checkbox-checked .px-checkbox-inner:after {
  transform: rotate(45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: " ";
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.px-checkbox-checked:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #f4c020;
  content: "";
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: hidden;
}

.px-dropdown {
  display: inline-table;
  margin: 0;
  max-width: 400px;
  width: max-content;
  max-height: 300px;
}

.px-dropdown-dt {
  margin: 0px;
  padding: 0px;
}

.px-dropdown-dt > a {
  width: 90px;
}

.px-dropdown a, .px-dropdown a:visited {
  color: #000;
  text-decoration: none;
  outline: none;
}

.px-table-filter {
  display: inline-block;
  position: relative;
  width: 0;
  height: 16px;
  cursor: pointer;
}

.px-table-filter-icon {
  color: #fff;
  position: absolute;
  top: 4px;
  left: 5px;
}

.px-table-filter-icon-active {
  color: #f4c020;
}

.px-icon {
  display: inline-block;
}

.px-dropdown-dd {
  display: block;
  margin: 0px;
  padding: 0px;
}

.px-dropdown-items {
  min-width: 300px;
  min-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  white-space: nowrap;
  top: 2px;
  left: 0px;
  list-style: none;
  border-radius: 2px;
  border: 1px solid #363636;
  padding: 0;
  width: auto;
}

.px-dropdown-items-wrapper {
  max-height: 1000px;
  overflow: auto;
}

.px-dropdown-items-multiple {
  display: table;
  padding: 0 5px;
  width: 100%;
  text-align: left;
}

.px-dropdown-items-multiple > .px-checkbox {
  display: flex !important;
  gap: 10px;
  align-items: center;
}

.px-dropdown-items-multiple span {
  font-size: 14px;
  font-weight: normal;
}

.px-dropdown-items-li {
  white-space: nowrap;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
}

.px-checkbox-content {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: text-bottom;
}

.px-checkbox-label {
  margin: 0 6px 0 3px;
  width: 100%;
  color: #ffffffd9 !important;
}

.px-dropdown-operation {
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  gap: 20px;
  padding: 8px 10px 4px 8px;
}

.px-dropdown-operation a {
  text-decoration: none;
  cursor: pointer;
}

.px-dropdown-filter-item {
  color: #ffffffd9;
}

.px-dropdown-filter-disable {
  color: #999;
  cursor: no-drop !important;
}

.px-dialog-close {
  color: #aaaaaa;
  font-size: 20px;
  text-decoration: none;
  padding: 10px;
  justify-self: end;
}

.px-dialog-close:hover {
  color: #919191;
}

.px-dialog:before {
  content: "";
  display: none;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10
}

.px-dialog-opened:before {
  display: block;
}
.px-dialog-opened .px-dialog-container {
  z-index: 10000;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  top: 35%;
}

.px-dialog-container {
  background-color: #363636;
  border: #333333 solid 0px;
  border-radius: 5px;
  margin-left: -200px;
  text-align:center;
  position: fixed;
  left: 50%;
  top: -100%;
  z-index: 11;
  width: 360px;
  box-shadow:0 5px 10px rgba(0,0,0,0.3);
  -webkit-transform: translate(0, -500%);
  -ms-transform: translate(0, -500%);
  transform: translate(0, -500%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  display: grid;
  padding: 0 15px 10px 15px;
}

.px-table-pagination {
  margin-top: 20px;
  text-align: right;
}

.px-pagination {
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  background-color: #1e1e1e;
  padding: 0;
  display: inline-block;
  margin: 0 4px;
  list-style-type: none;
}

.px-pagination-total {
  float: left;
  margin: 0 4px;
  color: #ffffffd9;
}

.px-pagination-disabled {
  cursor: not-allowed;
  border-color: #434343;
}

.px-pagination-disabled i {
  color: #666 !important;
  cursor: no-drop;
}

.px-pagination-li {
  min-width: 32px;
  height: 32px;
  float: left;
  margin-right: 4px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  text-align: center;
  list-style: none;
  background-color: #121212;
  border: 1px solid #434343;
  border-radius: 4px;
}

.px-pagination-li a {
  color: #ffffffd9;
}

.px-pagination-li li {
  color: #ffffffd9;
}

.px-pagination-pager {
  float: left;
}

.px-pagination-li-active {
  border-color: #f4c020;
}

.px-pagination-jump-next:after {
  content: "\2022\2022\2022";
  display: block;
  letter-spacing: 1px;
  color: #666;
  text-align: center;
}

.px-pagination-next i, .px-pagination-prev i {
  color: #fff;
}

.icon-vet-right-arrow:before {
  content: "\e6348";
}

.px-dropdown-numeric-filters {
  display: flex;
  gap: 20px;
  margin-top: 0 !important;
  padding-top: 0 !important;
}

::v-deep tbody > tr:hover {
  cursor: pointer !important;
  background-color: #896f1f !important;
}

@media screen and (max-width: 1600px) {
  ::v-deep th > span {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
}

::v-deep .v-data-footer {
  padding: 6px;
}

::v-deep .v-data-footer__select {
  display: none !important;
}

::v-deep .v-data-footer__pagination {
  margin-left: auto;
}
::v-deep .itemDisabled {
  color: grey;
  background-color: #9e9e9e1c;
}
::v-deep table > tbody > tr.itemDisabled:hover {
  pointer-events: none;
  background-color: #9e9e9e1c !important;
}

::v-deep th,
::v-deep td {
  font-size: 10px !important;
  padding: 0px 4px !important;
}

.table-pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
